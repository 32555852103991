.balance-card {
  /* background-color: #222; */
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  color: #3b3b3b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.balance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00d894;
}

.balance-title {
  font-weight: bold;
  font-size: 16px;
}

.balance-arrow {
  font-size: 20px;
}

.balance-amount {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  color: #3b3b3b;
}

.balance-change {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.balance-change-icon {
  margin-left: 5px;
  font-size: 12px;
}
